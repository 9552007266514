import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { SessionInfo } from 'types';
import { UserAvatar } from './header/UserAvatar';
import { Drawer } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
const navLinks = [
  { label: '아이폰 15 시리즈', link: '/reserve?modelName=iPhone 15' },
  { label: '갤럭시 Z 시리즈', link: '/reserve?modelName=갤럭시 Z' },
  { label: '갤럭시 S 시리즈', link: '/reserve?modelName=갤럭시 S' },
];

export const Header = () => {
  const client = useQueryClient();
  const sessionInfo = client.getQueryData<SessionInfo>(['session']);
  const [openMenu, setOpenMenu] = useState(false);

  const onCloseMenu = () => {
    setOpenMenu(false);
  };
  return (
    <div className='container mx-auto max-sm:px-8 flex items-center py-4'>
      <Link to='/'>
        <div className='flex flex-row items-center cursor-pointer'>
          <img src='/logo192.png' alt='와우스토어' className='h-12 max-md:hidden' />
          <h1 className='text-2xl max-md:hidden font-bold mx-2'>
            <span className='text-amber-500'>와우</span>스토어
          </h1>
        </div>
      </Link>
      <MenuRoundedIcon
        fontSize='large'
        className='!hidden max-md:!block cursor-pointer'
        onClick={() => setOpenMenu(true)}
      />
      <nav className='mx-4 max-md:hidden'>
        <ul className='flex'>
          {navLinks.map((navInfo) => (
            <li key={navInfo.label} className='mx-4 font-bold'>
              <Link to={navInfo.link}>{navInfo.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
      {/* <div className='ml-auto flex items-center'>
        {!sessionInfo ? (
          <Link to='/sign-in'>
            <div className='bg-amber-500 px-4 py-2 rounded-md text-white hover:bg-amber-600 cursor-pointer'>로그인</div>
          </Link>
        ) : (
          <UserAvatar />
        )}
      </div> */}
      <Drawer anchor={'left'} open={openMenu} onClose={onCloseMenu}>
        <div className='p-8'>
          <Link to='/'>
            <div className='flex flex-row items-center cursor-pointer'>
              <img src='/logo192.png' alt='와우스토어' className='h-12' />
              <h1 className='text-2xl font-bold mx-2'>
                <span className='text-amber-500'>와우</span>스토어
              </h1>
            </div>
          </Link>
          <ul>
            {navLinks.map((navInfo) => (
              <li key={navInfo.label} className='mx-4 my-8 font-bold text-xl'>
                <Link to={navInfo.link}>{navInfo.label}</Link>
              </li>
            ))}
          </ul>
        </div>
      </Drawer>
    </div>
  );
};
