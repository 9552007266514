import { Grid } from '@mui/material';
import { numberWithCommas } from 'common/numberWithCommas';
import React from 'react';

export const ComparePriceBox = () => {
  return (
    <div className='py-12'>
      <h1 className='font-bold text-4xl text-center my-16 px-8 break-keep max-sm:text-2xl max-sm:px-4'>
        <p className='my-1'>자급제, 와우 스토어 중</p> <p className='my-1'>어느 곳이 더 저렴할까요?</p>
      </h1>
      <div className='px-36 max-xl:px-16 max-lg:px-8 max-md:px-4'>
        <Grid
          container
          spacing={4}
          justifyContent={'center'}
          alignItems={'normal'}
          direction={{ xs: 'column', lg: 'row' }}
        >
          <Grid item xs={6}>
            <div className='h-full flex flex-col justify-between bg-neutral-50 border border-neutral-200 rounded-xl shadow-xl'>
              <div>
                <h3 className='text-center font-bold text-3xl max-sm:text-2xl rounded-t-xl bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white py-4'>
                  자급제
                </h3>
                <div className='px-4 py-4 text-xl max-sm:text-lg max-sm:px-2'>
                  <p className='py-2 px-4 font-bold text-2xl'>iPhone 15</p>
                  <div className='flex py-2 px-4'>
                    <p>출고가</p>
                    <p className='ml-auto'>125만원</p>
                  </div>
                  <div className='flex py-2 px-4'>
                    <p>
                      출고가 할인 <span className='font-bold text-indigo-500'>5%</span>
                    </p>
                    <p className='ml-auto font-bold text-red-500'>-6만원</p>
                  </div>
                  <div className='flex py-2 px-4 flex-wrap'>
                    <p>알뜰 요금</p>
                    <p className='ml-auto'>
                      {numberWithCommas(25900)}원 X 24개월{' '}
                      <span className='text-base'>({numberWithCommas(621600)}원)</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className='text-center py-2 px-4 font-bold text-2xl my-4'>
                <p>
                  총 비용 <span className='text-4xl text-indigo-500 mx-1'>181만원</span>
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className='h-full flex flex-col justify-between bg-neutral-50 border border-neutral-200 rounded-xl shadow-xl'>
              <div>
                <h3 className='text-center font-bold text-3xl max-sm:text-2xl rounded-t-xl bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white py-4'>
                  와우 스토어
                </h3>
                <div className='px-4 py-4 text-xl max-sm:text-lg max-sm:px-2'>
                  <p className='py-2 px-4 font-bold text-2xl'>iPhone 15</p>
                  <div className='flex py-2 px-4'>
                    <p>출고가</p>
                    <p className='ml-auto'>125만원</p>
                  </div>
                  {/* <div className='flex py-2 px-4'>
                    <p>
                      출고가 할인 <span className='text-pink-500 font-bold'>24%</span>
                    </p>
                    <p className='ml-auto font-bold text-red-500'>-30만원</p>
                  </div> */}
                  <div className='flex py-2 px-4 flex-wrap'>
                    <p>알뜰 요금</p>
                    <p className='ml-auto'>
                      {numberWithCommas(25900)}원 X 20개월{' '}
                      <span className='text-base'>({numberWithCommas(518000)}원)</span>
                    </p>
                  </div>
                  <div className='flex py-2 px-4 flex-wrap'>
                    <p>통신사 선약할인 요금</p>
                    <p className='ml-auto'>
                      {numberWithCommas(74250)}원 X 4개월{' '}
                      <span className='text-base'>({numberWithCommas(297000)}원)</span>
                    </p>
                  </div>
                  {/* <div className='flex py-2 px-4 flex-wrap'>
                    <p>
                      위약금 지원 <span className='text-sm text-stone-700'>(알뜰폰 변경 예약시)</span>
                    </p>
                    <p className='ml-auto text-red-500 font-bold'>최대 10만원 지원</p>
                  </div> */}
                </div>
              </div>
              <div className='text-center py-2 px-4 font-bold text-2xl my-4'>
                <p>
                  총 비용 <span className='text-4xl text-pink-500 mx-1'>206만원</span>
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
