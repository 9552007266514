import { BannerBox } from 'components/Main/BannerBox';
import { ComparePriceBox } from 'components/Main/ComparePriceBox';
import { GiftBox } from 'components/Main/GiftBox';
import { PhoneList } from 'components/Main/PhoneList';
import { ReviewBanner } from 'components/Main/ReviewBanner';
import { StepBox } from 'components/Main/StepBox';
import { Footer } from 'components/common/Footer';
import { Header } from 'components/common/Header';
import { Template } from 'components/common/Template';
import React from 'react';
import { Helmet } from 'react-helmet-async';

export const MainPage = () => {
  return (
    <Template header={<Header />} footer={<Footer />}>
      <>
        <Helmet>
          <title>와우 스토어 - WowStore</title>
        </Helmet>
        <BannerBox />
        {/* <GiftBox /> */}
        {/* <ReviewBanner /> */}
        <ComparePriceBox />
        <StepBox />
        <PhoneList />
      </>
    </Template>
  );
};
