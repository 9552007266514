import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { phonePlanList } from './PhonePlanDialog';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { MuiTelInput } from 'mui-tel-input';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { numberWithCommas } from 'common/numberWithCommas';
import { PhoneModelInfo } from 'types';

type ReserveFormPros = {
  phoneModelList: PhoneModelInfo[];
  phoneModel: string;
  phoneCapacity: string;
  prevTelecom: string;
  nextTelecom: string;
  phonePlan: string;
  discountType: string;
  paymentType: string;
  phoneNumber: string;
  baseAddress: string;
  detailAddress: string;
  name: string;
  birth: string;
  reqDetail: string;
  agree: boolean;
  mvno: boolean;
  selectGift: string;
  affiliateCard: string;
  onClickPrevTelecom(prevTelecom: string): void;
  onClickNextTelecom(nextTelecom: string): void;
  onClickDiscountType(discountType: string): void;
  onClickPaymentType(paymentType: string): void;
  onClickOpenPhonePlan(): void;
  onChangePhoneNumber(phoneNumber: any): void;
  onClickOpenPostCode(): void;
  onChangeDetailAddress(event: React.ChangeEvent<HTMLInputElement>): void;
  onChangeName(event: React.ChangeEvent<HTMLInputElement>): void;
  onChangeBirth(event: React.ChangeEvent<HTMLInputElement>): void;
  onChangeReqDetail(event: React.ChangeEvent<HTMLInputElement>): void;
  onClickRequestReserve(): void;
  onChangeAgree(event: React.ChangeEvent<HTMLInputElement>): void;
  onChangeMvno(event: React.ChangeEvent<HTMLInputElement>): void;
  onChangeSelectGift(event: SelectChangeEvent): void;
  onClickOpenComparePlan(): void;
  onClickOpenAffiliateCard(): void;
};

const prevTelecomList = [
  { label: 'SKT', imgUrl: 'skt.png' },
  { label: 'KT', imgUrl: 'kt.png' },
  { label: 'LG', imgUrl: 'lg.png' },
  { label: '알뜰폰', imgUrl: '' },
];

const nextTelecomList = [
  { label: 'SKT', imgUrl: 'skt.png' },
  { label: 'KT', imgUrl: 'kt.png' },
  { label: 'LG', imgUrl: 'lg.png' },
];

const paymentTypeList = [
  { label: '일시불', value: 1, recommend: false },
  { label: '24개월', value: 24, recommend: true },
  { label: '30개월', value: 30, recommend: false },
  { label: '36개월', value: 36, recommend: false },
];

const discountTypeList = [
  {
    discountType: '공시지원할인',
    recommend: false,
  },
  {
    discountType: '선택약정할인',
    recommend: true,
  },
];

export const ReserveForm = ({
  phoneModelList,
  phoneModel,
  phoneCapacity,
  prevTelecom,
  nextTelecom,
  phonePlan,
  discountType,
  paymentType,
  phoneNumber,
  baseAddress,
  detailAddress,
  name,
  birth,
  reqDetail,
  agree,
  mvno,
  selectGift,
  affiliateCard,
  onClickPrevTelecom,
  onClickNextTelecom,
  onClickDiscountType,
  onClickPaymentType,
  onClickOpenPhonePlan,
  onChangePhoneNumber,
  onClickOpenPostCode,
  onChangeDetailAddress,
  onChangeBirth,
  onChangeName,
  onChangeReqDetail,
  onClickRequestReserve,
  onChangeAgree,
  onChangeMvno,
  onChangeSelectGift,
  onClickOpenComparePlan,
  onClickOpenAffiliateCard,
}: ReserveFormPros) => {
  const selectPhonePlanInfo = phonePlanList.find((x) => x.telecom === nextTelecom);
  const selectPhonePlanDetail = selectPhonePlanInfo?.phonePlans.find((x) => x.name === phonePlan);
  const selectPhoneInfo: PhoneModelInfo | undefined = phoneModelList?.find(
    (iphoneInfo) => iphoneInfo.phone_name === phoneModel,
  );
  const selectPhoneCapacity = selectPhoneInfo?.capacities.find((x) => x.size === phoneCapacity);
  const selectPaymentTypeInfo = paymentTypeList.find((x) => x.label === paymentType);
  const selectDiscountTypeInfo = discountTypeList.find((x) => x.discountType === discountType);

  const getDevicePrice = () => {
    return selectPhoneCapacity?.price || 0;
  };

  const getDiscountPrice = () => {
    if (selectDiscountTypeInfo?.discountType === '공시지원할인') {
      return selectPhonePlanDetail?.discountList.find((x) => x.phoneName === phoneModel)?.discount || 0;
    } else if (selectDiscountTypeInfo?.discountType === '선택약정할인') {
      return selectPhonePlanDetail?.price || 0 * 0.25;
    }

    return 0;
  };

  const getDiscountDevicePrice = () => {
    let devicePrice = 0;

    if (!selectPhoneCapacity || !selectPaymentTypeInfo) {
      return devicePrice;
    }

    devicePrice = getDevicePrice();

    if (selectDiscountTypeInfo?.discountType === '공시지원할인') {
      devicePrice -= getDiscountPrice();
    }

    if (selectGift === '기기 할인') {
      if (['iPhone 15 Plus', 'iPhone 15 Pro Max'].includes(selectPhoneInfo?.phone_name || '')) {
        devicePrice -= 190000;
      } else {
        devicePrice -= 300000;
      }
    }

    return devicePrice;
  };

  const getMonthPrice = () => {
    let discountDevicePrice = getDiscountDevicePrice();

    if (!selectPaymentTypeInfo) {
      return discountDevicePrice;
    }

    if (selectPaymentTypeInfo.value === 1) {
      return 0;
    }

    return Math.round(discountDevicePrice / selectPaymentTypeInfo?.value);
  };

  const getPhonePlanPrice = () => {
    return selectPhonePlanDetail?.price || 0;
  };

  const getPhonePlanMonthPrice = () => {
    let phonePlanPrice = getPhonePlanPrice();

    if (!selectDiscountTypeInfo || !selectPaymentTypeInfo) {
      return phonePlanPrice;
    }

    if (selectDiscountTypeInfo?.discountType === '선택약정할인') {
      phonePlanPrice -= phonePlanPrice * 0.25;
    }

    return phonePlanPrice;
  };

  const getMonthInstallment = () => {
    const monthPrice = getMonthPrice();

    return Math.round(monthPrice * 0.059);
  };

  const getTotalMonthPirce = () => {
    return getMonthPrice() + getPhonePlanMonthPrice() + getMonthInstallment();
  };

  return (
    <>
      <Grid container spacing={4} direction={{ xs: 'column', lg: 'row-reverse' }}>
        <Grid item xs={6}>
          <div className='my-4'>
            <h3 className='text-xl font-bold my-2'>사용중인 통신사</h3>
            <Grid container spacing={1}>
              {prevTelecomList.map((prevTelecomInfo) => (
                <Grid item key={prevTelecomInfo.label}>
                  <div
                    className={`bg-neutral-100 rounded-xl border-2 p-4 cursor-pointer hover:border-blue-500 ${
                      prevTelecomInfo.label === prevTelecom ? 'border-blue-500' : 'border-neutral-100'
                    }`}
                    onClick={() => onClickPrevTelecom(prevTelecomInfo.label)}
                  >
                    {prevTelecomInfo.imgUrl ? (
                      <img src={prevTelecomInfo.imgUrl} alt={prevTelecomInfo.label} className='w-6 h-6' />
                    ) : (
                      <div className='font-bold'>{prevTelecomInfo.label}</div>
                    )}
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
          <div className='my-4'>
            <h3 className='text-xl font-bold my-2'>사용하실 통신사</h3>
            <Grid container spacing={1}>
              {nextTelecomList.map((nextTelecomInfo) => (
                <Grid item key={nextTelecomInfo.label}>
                  <div
                    className={`bg-neutral-100 rounded-xl border-2 p-4 cursor-pointer hover:border-blue-500 ${
                      nextTelecomInfo.label === nextTelecom ? 'border-blue-500' : 'border-neutral-100'
                    }`}
                    onClick={() => onClickNextTelecom(nextTelecomInfo.label)}
                  >
                    <img src={nextTelecomInfo.imgUrl} alt={nextTelecomInfo.label} className='w-6 h-6' />
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
          <div className='my-4'>
            <h3 className='text-xl font-bold my-2'>요금제</h3>
            <div
              className='border-blue-500 rounded-md border-2 px-4 py-2 cursor-pointer flex items-center'
              onClick={() => onClickOpenPhonePlan()}
            >
              <p>
                <span className='font-bold'>{selectPhonePlanDetail?.name}</span>{' '}
                <span className='text-neutral-400'>|</span>{' '}
                <span className='font-bold text-sm'>월 {numberWithCommas(getPhonePlanPrice())}원</span>
              </p>
              <ArrowDropDownRoundedIcon fontSize='large' className='!text-stone-700 ml-auto' />
            </div>
          </div>
          <div className='my-4'>
            <h3 className='text-xl font-bold my-2'>알뜰폰 요금제 변경</h3>
            <FormControlLabel
              control={<Checkbox checked={mvno} onChange={onChangeMvno} />}
              label='알뜰폰 요금제 변경 예약을 희망합니다.'
            />
          </div>
          <div className='my-4'>
            <h3 className='text-xl font-bold my-2'>할인 방법</h3>
            <Grid container spacing={2}>
              {discountTypeList.map((discountTypeInfo) => (
                <Grid item key={discountTypeInfo.discountType}>
                  <Badge badgeContent={discountTypeInfo.recommend ? '추천' : null} color='error'>
                    <div
                      className={`bg-neutral-100 rounded-xl border-2 p-4 cursor-pointer font-bold text-center hover:border-blue-500 ${
                        discountType === discountTypeInfo.discountType ? 'border-blue-500' : 'border-neutral-100'
                      }`}
                      onClick={() => onClickDiscountType(discountTypeInfo.discountType)}
                    >
                      <p>{discountTypeInfo.discountType}</p>
                      <p>
                        총{' '}
                        {discountTypeInfo.discountType === '선택약정할인'
                          ? numberWithCommas(selectPhonePlanDetail!.price * 0.25 * 24)
                          : numberWithCommas(
                              selectPhonePlanDetail?.discountList.find((x) => x.phoneName === phoneModel)?.discount,
                            )}
                        원
                      </p>
                    </div>
                  </Badge>
                </Grid>
              ))}
            </Grid>
          </div>
          <div className='my-4'>
            <h3 className='text-xl font-bold my-2'>구매 방법</h3>
            <Grid container spacing={2}>
              {paymentTypeList.map((paymentTypeInfo) => (
                <Grid item key={paymentTypeInfo.label}>
                  <Badge badgeContent={paymentTypeInfo.recommend ? '추천' : null} color='error'>
                    <div
                      className={`bg-neutral-100 rounded-xl border-2 p-4 cursor-pointer font-bold hover:border-blue-500 ${
                        paymentTypeInfo.label === paymentType ? 'border-blue-500' : 'border-neutral-100'
                      }`}
                      onClick={() => onClickPaymentType(paymentTypeInfo.label)}
                    >
                      {paymentTypeInfo.label}
                    </div>
                  </Badge>
                </Grid>
              ))}
            </Grid>
          </div>
          <div className='my-4'>
            <h3 className='text-xl font-bold my-2'>제휴카드</h3>
            <div
              className={`${
                affiliateCard ? 'border-blue-500' : 'border-neutral-200'
              } rounded-md border-2 px-4 py-2 cursor-pointer flex flex-wrap items-center hover:border-blue-500`}
              onClick={() => onClickOpenAffiliateCard()}
            >
              <div>
                <p className='font-bold'>
                  {affiliateCard ? affiliateCard.split('|')[0] : '제휴카드 할인을 선택하세요.'}
                </p>
                {affiliateCard && <span className='text-xs'>{affiliateCard.split('|')[1]}</span>}
              </div>
              <ArrowDropDownRoundedIcon fontSize='large' className='!text-stone-700 ml-auto' />
            </div>
          </div>
          {/* <div className='my-4'>
            <h3 className='text-xl font-bold my-2'>사은품 선택</h3>
            <FormControl fullWidth>
              <InputLabel id='gift-select-label'>사은품</InputLabel>
              <Select
                labelId='gift-select-label'
                value={selectGift}
                label='사은품을 선택해 주세요.'
                onChange={onChangeSelectGift}
              >
                <MenuItem value={'기기 할인'}>기기 할인</MenuItem>
                <MenuItem value={'AirPods Pro(2세대)'}>AirPods Pro(2세대)</MenuItem>
                <MenuItem value={'AirPods(3세대)'}>AirPods(3세대)</MenuItem>
              </Select>
            </FormControl>
          </div> */}
        </Grid>
        <Grid item xs={6} className='max-[1200px]:!pt-0'>
          <div className='my-4 text-right'>
            <p
              className='text-blue-800 font-bold border-b border-blue-800 inline-block cursor-pointer'
              onClick={() => onClickOpenComparePlan()}
            >
              자급제, 와우스토어 중 어떤게 더 저렴한가요?
            </p>
          </div>
          <div className='my-4'>
            <div className='flex px-4 py-4 border border-b-0 border-neutral-200 rounded-t-lg'>
              <p>출고가</p>
              <p className='ml-auto'>{numberWithCommas(getDevicePrice())}원</p>
            </div>
            {/* {selectGift === '기기 할인' && (
              <div className='flex px-4 py-4 border border-b-0 border-neutral-200'>
                <p className='text-red-500 font-bold'>와우스토어 특별할인</p>
                <p className='ml-auto text-red-500 font-bold'>
                  -
                  {['iPhone 15 Plus', 'iPhone 15 Pro Max'].includes(selectPhoneInfo?.phone_name || '')
                    ? numberWithCommas(190000)
                    : numberWithCommas(300000)}
                  원
                </p>
              </div>
            )} */}
            {selectDiscountTypeInfo?.discountType === '공시지원할인' && (
              <div className='flex px-4 py-4 border border-b-0 border-neutral-200'>
                <p>공시지원할인</p>
                <p className='ml-auto text-red-500 font-bold'>-{numberWithCommas(getDiscountPrice())}원</p>
              </div>
            )}
            <div className='flex px-4 py-4 border border-b-0 border-neutral-200'>
              <p>할부 원금</p>
              <p className='ml-auto'>{numberWithCommas(getDiscountDevicePrice())}원</p>
            </div>
            <div className='px-4 py-4 border border-neutral-200 rounded-b-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white'>
              {paymentType === '일시불' ? (
                <div className='font-bold'>일시불 구매가</div>
              ) : (
                <div className='flex'>
                  <p className='font-bold'>
                    월 할부금 <span className='text-sm'>({paymentType})</span>
                  </p>
                  <p className='ml-auto text-xl font-bold'>{numberWithCommas(getMonthPrice())}원</p>
                </div>
              )}
            </div>
          </div>
          <div className='my-4'>
            <div className='flex px-4 py-4 border border-b-0 border-neutral-200 rounded-t-lg'>
              <p>
                {selectPhonePlanInfo?.telecom} {selectPhonePlanDetail?.name}
              </p>
              <p className='ml-auto'>{numberWithCommas(getPhonePlanPrice())}원</p>
            </div>
            {selectDiscountTypeInfo?.discountType === '선택약정할인' && (
              <div className='flex px-4 py-4 border border-b-0 border-neutral-200'>
                <p>선택약정할인(25%)</p>
                <p className='ml-auto text-red-500 font-bold'>-{numberWithCommas(getPhonePlanPrice() * 0.25)}원</p>
              </div>
            )}
            <div className='flex px-4 py-4 border border-neutral-200 rounded-b-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white'>
              <p className='font-bold'>월 통신요금</p>
              <p className='ml-auto text-xl font-bold'>{numberWithCommas(getPhonePlanMonthPrice())}원</p>
            </div>
          </div>
          <div className='my-4'>
            <div className='px-4 py-4 border border-neutral-200 rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white'>
              <div className='flex'>
                <p className='font-bold'>월 납부 금액</p>
                <p className='ml-auto text-2xl font-bold'>{numberWithCommas(getTotalMonthPirce())}원</p>
              </div>
              <div className='text-right text-sm'>
                <p>※부가세(Vat) 포함 금액</p>
                <p>할부이자 할부원금 기준 5.9% {numberWithCommas(getMonthInstallment())}원 포함</p>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={4} direction={{ xs: 'column', lg: 'row-reverse' }}>
        <Grid item xs={6}>
          <div className='my-4'>
            <h3 className='text-xl font-bold my-2'>가입자 정보</h3>
            <Grid container spacing={2} direction={{ xs: 'column', lg: 'row' }}>
              <Grid item xs={4}>
                <TextField label='이름' variant='outlined' fullWidth onChange={onChangeName} value={name} />
              </Grid>
              <Grid item xs={4}>
                <MuiTelInput
                  defaultCountry='KR'
                  onlyCountries={['KR']}
                  label='개통할 휴대전화 번호'
                  variant='outlined'
                  fullWidth
                  value={phoneNumber}
                  onChange={onChangePhoneNumber}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label='생년월일'
                  variant='outlined'
                  fullWidth
                  onChange={onChangeBirth}
                  value={birth}
                  placeholder='YYMMDD 형태의 6자리로 입력해 주세요.'
                />
              </Grid>
            </Grid>
          </div>
          <div className='my-4'>
            <h3 className='text-xl font-bold my-2'>배송지 정보</h3>
            <Grid container spacing={2} direction={{ xs: 'column', lg: 'row' }}>
              <Grid item xs={6}>
                <TextField
                  label='주소'
                  variant='outlined'
                  fullWidth
                  onClick={() => onClickOpenPostCode()}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={baseAddress}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label='상세 주소'
                  variant='outlined'
                  fullWidth
                  value={detailAddress}
                  onChange={onChangeDetailAddress}
                />
              </Grid>
            </Grid>
          </div>
          <div className='my-4'>
            <h3 className='text-xl font-bold my-2'>요청사항</h3>
            <TextField
              label='기타 요청사항'
              variant='outlined'
              fullWidth
              value={reqDetail}
              onChange={onChangeReqDetail}
              placeholder='인터넷, 휴대폰 결합 진행 원해요 등'
            />
          </div>
          <div className='my-4'>
            <h3 className='text-xl font-bold my-2'>약관 및 동의</h3>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography fontWeight={500}>개통 후 125일간은 반드시 회선을 유지 해주셔야 합니다.</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className='text-xs break-keep'>
                  <p>
                    *개통 후 회선유지 125일 (기간내 회선 해지, 타통신사 재이동, 일시정지시 약정 위약금 100% 부과및
                    단말기 할부금이 청구되오니 유의하시기 바랍니다)
                  </p>
                  <p>
                    *선택약정할인시 요금제 유지 125일 / 공시지원금 약정시 요금제 유지 185일 유지 후 요금하향 하실 수
                    있습니다. (5G 개통시 SKT는 45,000원, KT는 47,000원, LGU+는 5G 이상 요금제를 유지해 주셔야 합니다.)
                  </p>
                  <p>
                    *가족결합, 제휴카드, 휴대폰 보험은 고객센터를 통해 정확한 내용을 확인하시고 신청하셔야 하며, 개통이
                    완료된 후에 114 고객센터를 통해 신청하셔야 합니다. 가족결합, 제휴카드, 휴대폰 보험에 대한 누락에
                    대한 책임은 자사에 없음을 미리 고지 드립니다.
                  </p>
                  <p>
                    *휴대폰을 수령하시고 익일 안으로 자동으로 개통이 진행됩니다. 별도로 개통을 미루거나 중지해야 하는
                    사유가 있는 경우 고객센터에 따로 문의하여 일정을 조율해주시기 바랍니다.
                  </p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography fontWeight={500}>위약금은 신청 전 미리 확인 해주세요.</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className='text-xs break-keep'>
                  <p>* 통신사 이동, 기기변경, 요금 변경이나 해지등으로 인한 위약금이 발생될 수 있습니다.</p>
                  <p>* 사전에 통신사 고객센터(114)에 확인 후 신청 부탁드립니다.</p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography fontWeight={500}>휴대폰 개통을 위한 개인정보 수집 동의</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className='text-xs break-keep'>
                  <p>
                    본인은 본사이트에서 구매 및 이용하기 위해서, 다음과 '회사'가 본인의 개인정보를 수집, 이용하고
                    서비스의 제공을 위해 개인정보의 취급을 위탁하는 것에 동의합니다.
                  </p>
                  <p>• 수집항목 : 이름, 생년월일, 휴대폰 번호, 배송지 주소</p>
                  <p>
                    • 이용목적 : 휴대폰 및 통신서비스 가입가능여부 조회, 휴대폰 및 통신 서비스 개통, 고객 상담 및
                    MMS광고 발송
                  </p>
                  <p>
                    • 개인정보의 보유기간 및 이용기간 : 개인 정보 획득일로부터 6개월간 보유 및 이용 위 개인 정보 수집,
                    이용 및 취급위탁에 동의하지 않으실 경우, 신청을 하실 수 없습니다.
                  </p>
                  <p>
                    회사가 제공하는 서비스와 관련된 개인정보의 취급과 관련된 사항은, 회사가 운영하는 개인정보취급방침에
                    따릅니다.
                  </p>
                  <p>본인은 위 내용을 숙지하였으며 이에 동의합니다.</p>
                </div>
              </AccordionDetails>
            </Accordion>
            <div className='break-keep my-4'>
              <FormControlLabel
                control={<Checkbox checked={agree} onChange={onChangeAgree} />}
                label='모든 약관을 확인하였으며, 약관 내용에 동의합니다.'
              />
            </div>
          </div>
          <div className='my-4'>
            <Button
              variant='contained'
              fullWidth
              size='large'
              onClick={() => onClickRequestReserve()}
              className='bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 font-bold !text-white'
            >
              온라인 신청하기
            </Button>
          </div>
        </Grid>
        {/* <Grid item xs={6}></Grid> */}
      </Grid>
    </>
  );
};
